<template>
    <div class="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50" v-show="loading">
        <span class="opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0" style="top: 50%">
            <svg class="animate-spin ml-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
            </svg>
        </span>
    </div>

    <div v-if="step === 1">
        <div class="sm:mx-0 sm:grid sm:grid-cols-6" v-if="!loading">
            <div class="sm:col-start-2 sm:col-span-4">
                <Form @submit="gotoPayment" :validation-schema="validationRulesStep1" v-slot="{ errors }">
                    <!--setFieldValue setValues -->
                    <div class="mb-5">
                        <a @click="$router.go(-1)" class="cursor-pointer inline-flex justify-start">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M9.70711 16.7071C9.31658 17.0976 8.68342 17.0976 8.29289 16.7071L2.29289 10.7071C1.90237 10.3166 1.90237 9.68342 2.29289 9.29289L8.29289 3.29289C8.68342 2.90237 9.31658 2.90237 9.70711 3.29289C10.0976 3.68342 10.0976 4.31658 9.70711 4.70711L5.41421 9H17C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11L5.41421 11L9.70711 15.2929C10.0976 15.6834 10.0976 16.3166 9.70711 16.7071Z"
                                    fill="currentColor"
                                />
                            </svg>
                            <div class="ml-3">{{ $t('walletorder.button.back') }}</div>
                        </a>
                    </div>

                    <div class="relative">
                        <!-- error alert -->
                        <div v-if="error_messages.length > 0" class="mb-5 rounded-md bg-red-50 p-4">
                            <div class="flex">
                                <div class="flex-shrink-0">
                                    <svg
                                        class="h-5 w-5 text-red-400"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                            clip-rule="evenodd"
                                        />
                                    </svg>
                                </div>
                                <div class="ml-3" v-for="(error, index) in error_messages" :key="index">
                                    <h3 class="text-sm font-medium text-red-800">
                                        {{ error.message }}
                                    </h3>
                                    <div v-if="error.unavailable_items" class="mt-2 text-sm text-red-700">
                                        <ul class="list-disc pl-5 space-y-1">
                                            <li v-for="(item, index_item) in error.unavailable_items" :key="index_item">
                                                {{ item }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <!-- carddetails -->
                            <div class="content-container checkout-box radius-anchor">
                                <div class="pb-2 mb-3">
                                    <div class="text-center">
                                        <h2 class="font-bold text-2xl text-center mt-3">{{ $t('walletorder.title') }}</h2>
                                    </div>

                                    <div v-if="card_valid == null" class="rounded-md my-5 bg-yellow-50 p-4 info-message">
                                        <div class="flex">
                                            <div class="flex-shrink-0">
                                                <svg
                                                    class="h-5 w-5 text-yellow-400"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                                        clip-rule="evenodd"
                                                    />
                                                </svg>
                                            </div>
                                            <div class="ml-3">
                                                <h3 class="text-sm font-medium text-yellow-800 info-message-text">
                                                    {{ $t('walletorder.alert.enter.title') }}
                                                </h3>
                                                <div class="mt-2 text-sm text-yellow-700 info-message-text">
                                                    <p>{{ $t('walletorder.alert.enter.description') }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else-if="card_valid" class="rounded-md my-5 bg-green-50 p-4 info-message">
                                        <div class="flex">
                                            <div class="flex-shrink-0">
                                                <svg
                                                    class="h-5 w-5 text-green-400"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                                        clip-rule="evenodd"
                                                    />
                                                </svg>
                                            </div>
                                            <div class="ml-3">
                                                <h3 class="text-sm font-medium text-green-800 info-message-text">
                                                    {{ $t('walletorder.alert.valid.title') }}
                                                </h3>
                                                <div
                                                    class="mt-2 text-sm text-green-700 info-message-text"
                                                    v-if="card && (pincodeRequired || showbalance)"
                                                >
                                                    <p v-if="coins">
                                                        {{ $t('walletorder.alert.valid.description') }}
                                                        <span class="amount">
                                                            {{ $n(card.balance, 'coins') }}
                                                            {{ location.wallet.coin_label ? location.wallet.coin_label : $t('order.coins') }}
                                                        </span>
                                                    </p>
                                                    <p v-else>
                                                        {{ $t('walletorder.alert.valid.description') }}
                                                        <span class="amount"> {{ $n(card.balance, 'currency') }} </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="rounded-md my-5 bg-red-50 p-4 info-message">
                                        <div class="flex">
                                            <div class="flex-shrink-0">
                                                <svg
                                                    class="h-5 w-5 text-red-400"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                                        clip-rule="evenodd"
                                                    />
                                                </svg>
                                            </div>
                                            <div class="ml-3">
                                                <h3 class="text-sm font-medium text-red-800 info-message-text">
                                                    {{ $t('walletorder.alert.invalid.title') }}
                                                </h3>
                                                <div class="mt-2 text-sm text-red-700 info-message-text">
                                                    <p>{{ card_message }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="-ml-2 -mt-2 flex flex-wrap items-baseline">
                                        <h3 class="ml-2 mt-2 text-lg leading-6">
                                            {{ $t('walletorder.cardetails') }}
                                        </h3>
                                    </div>
                                </div>

                                <div class="mb-3">
                                    <div class="sm:grid sm:grid-cols-12 gap-3 mt-1">
                                        <div class="sm:col-span-6">
                                            <label for="cardnumber" class="block text-xs" :class="{ 'text-red-500': errors.cardnumber }"
                                                >{{ $t('fields.cardnumber') }} *</label
                                            >
                                            <div class="mt-1">
                                                <Field
                                                    type="text"
                                                    @change="carddetailsChanged()"
                                                    @blur="checkCardnumber()"
                                                    v-model.trim="form.cardnumber"
                                                    autocomplete="off"
                                                    name="cardnumber"
                                                    class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent"
                                                    :class="{ error: errors.cardnumber }"
                                                />
                                            </div>
                                        </div>
                                        <div class="sm:col-span-2 mt-2 sm:mt-0" v-if="pincodeRequired == true">
                                            <label for="cardnumber" class="block text-xs" :class="{ 'text-red-500': errors.pincode }"
                                                >{{ $t('fields.pincode') }} *</label
                                            >
                                            <div class="mt-1">
                                                <Field
                                                    type="text"
                                                    @change="carddetailsChanged()"
                                                    @blur="checkCardnumber()"
                                                    v-model.trim="form.pincode"
                                                    autocomplete="off"
                                                    name="pincode"
                                                    class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent"
                                                    :class="{ error: errors.cardnumber }"
                                                />
                                            </div>
                                        </div>
                                        <div class="sm:mt-0 sm:col-span-6">
                                            <label class="block text-xs">&nbsp;</label>
                                            <div class="mt-1">
                                                <modal-scan-wallettoken @scanned="codeScanned($event)" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mb-4">
                                    <label for="amount" class="block text-xs" :class="{ 'text-red-500': !form.amount && !prestine }"
                                        >{{ $t('fields.amount-select') }} *</label
                                    >
                                    <div class="flex flex-wrap gap-3 mt-1 coins" v-if="coins == true">
                                        <div
                                            v-for="(coin_amount, index) in buttonValues"
                                            @click="setAmount(coin_amount)"
                                            :key="index"
                                            class="btn checkout-option"
                                            :class="{ 'input-selected': coin_amount == form.coin_amount }"
                                        >
                                            <span class="mr-5"
                                                >{{ $n(parseFloat(coin_amount), 'coins') }}
                                                {{ location.wallet.coin_label ? location.wallet.coin_label : $t('order.coins') }}</span
                                            >
                                            <span>{{ $n(parseFloat(coin_amount * coinValue), currency) }}</span>
                                        </div>
                                    </div>
                                    <div class="flex flex-wrap gap-3 mt-2" v-else>
                                        <div
                                            v-for="(amount, index) in buttonValues"
                                            @click="setAmount(amount)"
                                            :key="index"
                                            class="btn checkout-option"
                                            :class="{ 'input-selected': amount == form.amount }"
                                        >
                                            <span>{{ $n(parseFloat(amount), currency) }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mt-5 mb-10 relative">
                            <button
                                type="submit"
                                :class="{ disabled: !this.card_valid }"
                                class="w-full inline-flex justify-center items-center px-6 py-3 btn btn-primary-outline text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150"
                            >
                                {{ $t('walletorder.button.next') }}
                                <svg class="ml-3 -mr-1 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                    <path
                                        fill-rule="evenodd"
                                        d="M10.2929 3.29289C10.6834 2.90237 11.3166 2.90237 11.7071 3.29289L17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L11.7071 16.7071C11.3166 17.0976 10.6834 17.0976 10.2929 16.7071C9.90237 16.3166 9.90237 15.6834 10.2929 15.2929L14.5858 11L3 11C2.44772 11 2 10.5523 2 10C2 9.44772 2.44772 9 3 9H14.5858L10.2929 4.70711C9.90237 4.31658 9.90237 3.68342 10.2929 3.29289Z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>

    <div v-if="step === 2">
        <div class="sm:mx-0 sm:grid sm:grid-cols-6" v-if="!loading">
            <div class="sm:col-start-2 sm:col-span-4">
                <Form @submit="onSubmit" :validation-schema="validationRulesStep2" v-slot="{ errors }">
                    <!--setFieldValue setValues -->
                    <div class="mb-5">
                        <a @click="step = 1" class="cursor-pointer flex justify-start">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M9.70711 16.7071C9.31658 17.0976 8.68342 17.0976 8.29289 16.7071L2.29289 10.7071C1.90237 10.3166 1.90237 9.68342 2.29289 9.29289L8.29289 3.29289C8.68342 2.90237 9.31658 2.90237 9.70711 3.29289C10.0976 3.68342 10.0976 4.31658 9.70711 4.70711L5.41421 9H17C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11L5.41421 11L9.70711 15.2929C10.0976 15.6834 10.0976 16.3166 9.70711 16.7071Z"
                                    fill="currentColor"
                                />
                            </svg>
                            <div class="ml-3">{{ $t('walletorder.button.back') }}</div>
                        </a>
                    </div>

                    <div class="relative">
                        <div>
                            <div class="wallettopup content-container checkout-box radius-anchor desktop-cart">
                                <!-- <div class="pb-2 mb-3">
                                    <div class="-ml-2 -mt-2 flex flex-wrap items-baseline">
                                        <h3 class="ml-2 mt-2 text-lg leading-6">
                                            {{ $t('order.walletamount') }}
                                        </h3>
                                    </div>
                                </div> -->

                                <h2 class="text-xl font-bold mb-3">{{ $t('shoppingcart.title') }}</h2>

                                <div class="ccss--modal-shopping-cart-content">
                                    <div class="overflow-auto cart-items-wrapper">
                                        <div class="flex justify-between items-start flex-grow">
                                            <span v-if="form.coin_amount" class="font-bold">
                                                {{ $n(parseFloat(form.coin_amount), 'coins') }}
                                                {{ location.wallet.coin_label ? location.wallet.coin_label : $t('order.coins') }}
                                            </span>
                                            {{ $n(parseFloat(form.amount), 'currency') }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- your details -->
                            <div class="content-container p-5 mt-5 border shadow-sm rounded-sm checkout-box radius-anchor">
                                <div class="pb-2 mb-3">
                                    <div class="-ml-2 -mt-2 flex flex-wrap items-baseline">
                                        <h2 class="ml-2 mt-2 text-2xl leading-6">
                                            {{ $t('order.personalinformation') }}
                                        </h2>
                                    </div>
                                </div>

                                <div class="mb-3">
                                    <label for="customer_email" class="block text-xs" :class="{ 'text-red-500': errors.customer_email }"
                                        >{{ $t('fields.email') }} *</label
                                    >
                                    <div class="mt-1">
                                        <Field
                                            type="text"
                                            v-model.trim="form.customer_email"
                                            autocomplete="off"
                                            name="customer_email"
                                            class="lowercase py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent"
                                            :class="{ error: errors.customer_email }"
                                        />
                                    </div>
                                </div>
                            </div>

                            <!-- paymenttypes -->
                            <paymenttypes
                                :amount="amount"
                                :form-valid="{ valid: form.customer_email.length > 0 && !errors.customer_email }"
                                ref="paymenttypes"
                                :disabled="saving"
                                v-if="paymenttypesAvailable === true"
                                @selected="setPaymenttype($event)"
                            />
                        </div>

                        <div class="mt-5 mb-10 relative">
                            <button
                                :disabled="saving"
                                type="submit"
                                class="w-full inline-flex justify-center items-center px-6 py-3 btn btn-primary-outline text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150"
                            >
                                {{ $t('walletorder.button.confirm') }}
                                ({{ $n(amount, currency) }})
                                <svg
                                    v-if="saving"
                                    class="animate-spin ml-3 h-5 w-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path
                                        class="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                <svg v-else class="ml-3 -mr-1 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                    <path
                                        fill-rule="evenodd"
                                        d="M10.2929 3.29289C10.6834 2.90237 11.3166 2.90237 11.7071 3.29289L17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L11.7071 16.7071C11.3166 17.0976 10.6834 17.0976 10.2929 16.7071C9.90237 16.3166 9.90237 15.6834 10.2929 15.2929L14.5858 11L3 11C2.44772 11 2 10.5523 2 10C2 9.44772 2.44772 9 3 9H14.5858L10.2929 4.70711C9.90237 4.31658 9.90237 3.68342 10.2929 3.29289Z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import md5 from 'js-md5';

import * as _api from '../../lib/api';
import * as _location from '../../lib/location';
import * as _log from '../../lib/log';
import * as _state from '../../lib/state';
import * as _payment from '../../lib/payment';
import * as _transaction from '../../lib/transaction';
import * as _notification from '../../lib/notification';

// mixins
import CheckoutHelper from '../../mixins/checkout.helper.js';
import CheckoutPaymenttypeHelper from '../../mixins/checkout-paymenttype.helper.js';

// import moment from 'moment'
import { Field, Form } from 'vee-validate';
import * as yup from 'yup';

// modals
import Paymenttypes from '../../components/Paymenttypes.vue';
import ModalScanWallettoken from '../../components/ModalScanWallettoken.vue';

// set the logger
const logger = _log.get('WALLET CREDIT');

export default {
    name: 'CheckoutWallet',
    props: ['location_slug'],
    mixins: [CheckoutHelper, CheckoutPaymenttypeHelper],
    components: {
        Field,
        Form,
        Paymenttypes,
        ModalScanWallettoken,
    },
    data() {
        let location = _state.get('location/getLocation');

        let validationRules = {
            cardnumber: yup.string().required().max(255),
            // amount: yup.number().required(),
        };

        if (location && location.wallet && location.wallet.pincode_required) {
            // if (location.wallet.pincode_required) {
            validationRules.pincode = yup.string().required().max(255);
        }

        return {
            loading: false,
            saving: false,
            step: 1,
            prestine: true,

            validationRulesStep1: yup.object().shape(validationRules),
            validationRulesStep2: yup.object().shape({
                customer_email: yup.string().required().email().max(255),
            }),

            form: {
                paymenttype_id: '',
                cardnumber: this.$route.query.cardnumber || '',
                pincode: this.$route.query.pincode || '',
                coin_amount: 0,
                amount: '',
                customer_email: this.user ? this.user.username : '',
                customer_phone_number: '',
            },

            error_messages: [],

            card: null,
            card_valid: null,
            card_message: null,
            cashfunction: null,

            paymenttypes: [],
        };
    },
    async mounted() {
        if (!this.location.slug) {
            await _location.init(this.location_slug);
        }

        window.checkout = this;

        axios.defaults.headers.common['Location'] = this.location_slug;

        if (
            (this.$route.query.cardnumber && this.$route.query.pincode) ||
            (this.$route.query.cardnumber && this.location.wallet.pincode_required == false)
        ) {
            this.checkCardnumber();
        }
    },

    methods: {
        async gotoPayment() {
            if (!this.card_valid) {
                return;
            }

            this.prestine = false;

            if (!this.form.amount) {
                return;
            }

            // clear transactions]
            _transaction.clear();

            // create transaction in localstorage
            let data = {
                type: 'cashinout',
                wallettoken_id: this.card.id,
                cashfunction_id: this.cashfunctionId,
                quantity: 1,
                coin_amount: this.form.coin_amount,
                rate: this.form.amount,
                description: 'Wallet credit',
            };

            // parent_id: data.id, (data_addon.id = md5(JSON.stringify(data_addon)));
            // data_addon.quantity = parseInt(addon.quantity);

            // data_addons.push(data_addon);

            // add the addon to the transaction
            // _transaction.addItem(data_addon);
            data.id = md5(JSON.stringify(data));
            data.quantity = 1;

            await _transaction.addItem(data);

            console.log('tra====', _state.get('transaction/getTransaction'));

            this.step = 2;
        },

        setPaymenttype(paymenttype) {
            console.log('ddd');
            this.paymenttype = paymenttype;
            this.form.paymenttype_id = paymenttype.id;
        },

        async onSubmit(data) {
            this.saving = true;
            // todo: replace with global notification
            if (this.cashfunctionId == false) {
                _notification.set('cashfunction.not.selected', 'error');
                this.saving = false;
                return;
            }

            if (!data.paymenttype_valid) {
                if (this.$refs.paymenttypes.validate() === false || data.paymenttype_valid) {
                    return;
                }
            }
            // is the card still valid
            let card_valid = await this.checkCardnumber().then((response) => {
                if (response.result == 'success') {
                    return true;
                }
                return false;
            });

            if (!card_valid) {
                this.saving = false;
                return;
            }

            // create the transaction
            await this.createTransaction();

            // setTimeout(function() {
            //     window.checkout.saving = false;
            //     // window.checkout.$refs.paymenttypes.clearPaymenttype();
            // }, 1000);
        },

        async createTransaction() {
            let paymentAmount = this.form.amount;
            let dataLines = [
                {
                    type: 'cashinout',
                    wallettoken_id: this.card.id,
                    cashfunction_id: this.cashfunctionId,
                    quantity: 1,
                    coin_amount: this.form.coin_amount,
                    rate: this.form.amount,
                    description: 'Wallet credit',
                },
            ];

            // paymentfee
            let transaction = _state.get('transaction/getTransaction');

            let linesTransactionIndex = transaction.lines.findIndex((ln) => {
                return ln.id === 'TRX_FEE';
            });

            if (linesTransactionIndex > 0) {
                paymentAmount = paymentAmount + transaction.lines[linesTransactionIndex].rate;
                dataLines.push({
                    id: 'TRX_FEE',
                    type: 'revenue',
                    rate: transaction.lines[linesTransactionIndex].rate,
                    rate_without_discount: transaction.lines[linesTransactionIndex].rate,
                    quantity: 1,
                    item_id: transaction.lines[linesTransactionIndex].item_id,
                    description: 'Payment fee',
                });
            }

            let data = {
                method: 'creditwallet',
                customer_email: this.form.customer_email,
                lines: dataLines,
            };

            if ((await _transaction.sendWithData(data)) == false) {
                _notification.set('notification.transaction.error', 'error');
                return;
            }
            logger.log('success', 'walllet transaction')();

            this.makePayment(paymentAmount);
        },

        async makePayment(paymentAmount) {
            // make payment
            const response = await _payment.send(paymentAmount);

            logger.log('payment response', response)();

            if (response && response.result != 'success') {
                // todo: translate
                _notification.set('notification.payment.error', 'error');
                return;
            }

            _payment.handleResultCode(this.location_slug, response.data);
        },

        async carddetailsChanged() {
            this.card = null;
            this.card_valid = null;
        },

        codeScanned(event) {
            this.form.cardnumber = event;
            this.checkCardnumber();
        },

        async checkCardnumber() {
            if (!this.form.cardnumber) {
                return;
            }

            if (!this.form.pincode && this.pincodeRequired == true) {
                return;
            }
            // todo: needs testing
            const response = await _api.post('wallet/token/check', {
                barcode: this.form.cardnumber,
                pincode: this.form.pincode,
                type: this.coins ? 'coins' : 'cash',
                connect_new_wallet: this.connectNewBalance ? true : false,
            });

            if (!response) {
                return;
            }

            if (response.result === 'success') {
                this.card = {
                    id: response.id,
                    balance: response.balance,
                };
                this.card_valid = true;
            } else {
                this.card_valid = false;
                this.card_message = response.message;
            }

            return response;
        },

        setAmount(amount) {
            if (this.coins == true) {
                this.form.coin_amount = parseFloat(amount);
                this.form.amount = this.form.coin_amount * this.coinValue;

                return;
            }
            this.form.coin_amount = 0;
            this.form.amount = amount;
        },
    },
    computed: {
        location() {
            return _state.get('location/getLocation');
        },

        coins() {
            return this.location.wallet.coins;
        },

        cashfunctionId() {
            return this.location.wallet.cashfunction_id;
        },

        coinValue() {
            return parseFloat(this.location.wallet.coin_value);
        },

        buttonValues() {
            return this.location.wallet.values ? this.location.wallet.values.split(';') : '';
        },

        connectNewBalance() {
            return this.location.wallet.connect_new_balance;
        },

        pincodeRequired() {
            return this.location.wallet.pincode_required;
        },

        showbalance() {
            return this.location.wallet.showbalance;
        },

        paymenttypesAvailable() {
            return this.form.amount > 0;
        },
        config() {
            return _state.get('config/getConfig');
        },
        currency() {
            return this.config.settings.currency;
        },

        transaction() {
            return _state.get('transaction/getTransaction');
        },

        amount() {
            return this.transaction.total_unpaid;
        },
    },
};
</script>

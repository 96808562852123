export default {
    methods: {
        redirectToRoute(name, params, query) {
            params.location_id = this.location_slug;

            window.checkout.endSubmit();
            window.checkout.$refs.paymenttypes.clearPaymenttype();

            this.$router.push({ name: name, params: params, query: query });
        },

        redirectToUrl(url) {
            window.checkout.endSubmit();
            window.checkout.$refs.paymenttypes.clearPaymenttype();
            window.location = url;
        },

        endSubmit() {
            this.saving = false;
            return false;
        },
    },
};

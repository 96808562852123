<template>
  <div class="content-container mt-5 checkout-box radius-anchor payment-box">
    <div class="pb-2 mb-3">
      <div class="-ml-2 -mt-2 flex flex-wrap items-baseline">
        <h2 class="ml-2 mt-2 text-2xl font-bold leading-6">
          {{ $t('order.paymentmethod') }}
        </h2>
      </div>
      <!-- <div class="text-xs text-red-500" v-if="errors.selectedPaymenttype.id"> -->
      <div
        v-if="valid === false"
        class="text-xs text-red-500"
      >
        {{ $t('order.paymentmethod.required') }}
      </div>
    </div>

    <!-- apple pay -->
    <div v-if="amount > 0">
      <div
        v-if="paymenttypeApplePay && getBrowserVersion() === 'safari'"
        v-show="formValid.valid == false"
        class="mb-3 applepay-wrapper-form-checker"
      >
        <a
          class="applepay_placeholder adyen-checkout__applepay__button--black adyen-checkout__applepay__button--plain ApplePayButton-module_apple-pay__1liGp ApplePayButton-module_apple-pay-button__26P3- ApplePayButton-module_apple-pay-button-black__3Ml54 ApplePayButton-module_apple-pay-button--type-plain__2mnnX"
          @click="validateForm()"
        />
      </div>
      <div
        v-if="paymenttypeApplePay && getBrowserVersion() === 'safari'"
        v-show="formValid.valid == true"
        class="mb-3 applepay-wrapper"
      >
        <div :id="'paymenttype_applepay'" />
        <a
          class="applepay_placeholder adyen-checkout__applepay__button--black adyen-checkout__applepay__button--plain ApplePayButton-module_apple-pay__1liGp ApplePayButton-module_apple-pay-button__26P3- ApplePayButton-module_apple-pay-button-black__3Ml54 ApplePayButton-module_apple-pay-button--type-plain__2mnnX"
          @click="validateForm()"
        />
      </div>

      <!-- google pay -->
      <div
        v-if="paymenttypeGooglePay && getBrowserVersion() !== 'safari'"
        class="mb-3 googlepay-wrapper"
      >
        <div :id="'paymenttype_googlepay'" />
      </div>
    </div>

    <div
      v-for="(paymenttype, index) in paymenttypes"
      :key="index"
    >
      <div
        v-if="isAvailable(paymenttype, amount) && !paymenttypesDirect.includes(paymenttype.attributes.psp_method)"
        class="payment-method cursor-pointer flex-grow relative border p-4 flex radius-anchor"
        :class="[selectedPaymenttype.id == paymenttype.id ? 'active' : '', disabled ? 'disabled' : '']"
        @click="selectPaymenttype(paymenttype)"
      >
        <div class="flex items-center h-5">
          <input
            :id="'paymenttype_' + paymenttype.id"
            v-model="selectedPaymenttype.id"
            type="radio"
            :checked="selectedPaymenttype.id == paymenttype.id"
            name="selectedPaymenttype.id"
            :value="paymenttype.id"
            class="focus:outline-none focus:ring-transparent h-4 w-4 text-highlight cursor-pointer border"
          >
        </div>

        <payment-icon :psp_method="paymenttype.attributes.psp_method" />

        <label class="ml-3 flex flex-col cursor-pointer">
          <span
            class="block text-sm font-medium"
          >{{ paymenttype.attributes.webshop_label ? paymenttype.attributes.webshop_label : paymenttype.attributes.name }}
            <span
              v-if="paymenttype.attributes.fee && paymenttype.attributes.fee.percentage > 0"
              class="transactionfee"
            >
              <span v-if="paymenttype.attributes.fee.type == 'percentage'"> +{{ paymenttype.attributes.fee.percentage }}% </span>
              <span v-if="paymenttype.attributes.fee.type == 'fixed'"> + €{{ paymenttype.attributes.fee.percentage }} </span>
            </span>
          </span>
        </label>
      </div>
      <!-- <div v-if="(selectedPaymenttype.id == paymenttype.id || paymenttype.attributes.psp_method !== 'applepay') && paymenttype.attributes.psp_type == 'adyen_ecommerce'" class="adyenpayment" :id="'paymenttype_' + paymenttype.attributes.psp_method"></div> -->
      <!-- <div v-if="selectedPaymenttype.id == paymenttype.id && paymenttype.attributes.psp_type == 'adyen_ecommerce'" class="adyenpayment" :id="'paymenttype_' + paymenttype.attributes.psp_method"></div> -->
      <div
        v-if="
          selectedPaymenttype.id == paymenttype.id &&
            paymenttype.attributes.psp_type == 'adyen_ecommerce' &&
            paymenttype.attributes.psp_method !== 'applepay' &&
            amount > 0
        "
        :id="'paymenttype_' + paymenttype.attributes.psp_method"
        class="adyenpayment"
      >
        <!--  payment loader-->
        <!-- <div v-if="!['giropay', 'bcmc_mobile', 'googlepay'].includes(paymenttype.attributes.psp_method)" class="payment-loader">
                    <div class="snippet" data-title=".dot-pulse">
                        <div class="stage">
                            <div class="dot-pulse"></div>
                        </div>
                    </div>
                </div> -->
      </div>
    </div>
  </div>
</template>

<style>
button.gpay-button {
    width: 100% !important;
}
</style>

<script>
// libs
import * as _api from '../lib/api';
import * as _payment from '../lib/payment';
import * as _transaction from '../lib/transaction';
import * as _state from '../lib/state';
import PaymentIcon from './PaymentIcon';

export default {
    name: 'Paymenttypes',
    components: {
        PaymentIcon,
    },
    props: ['amount', 'formValid', 'disabled'],
    data() {
        return {
            loading: false,
            valid: true,
            selectedPaymenttype: {},
            paymenttypes: [],
            paymenttypeApplePay: false,
            paymenttypeGooglePay: false,
            paymenttypesDirect: ['applepay', 'googlepay'],
        };
    },

    computed: {
        transaction() {
            return _state.get('transaction/getTransaction');
        },

        account_id() {
            return this.transaction.account.id;
        },
    },

    watch: {
        account_id() {
            if (!this.transaction.account.id) {
                return;
            }
            this.getPaymenttypes();
        },
    },

    async mounted() {
        window.paymenttypes = this;
        await this.getPaymenttypes();

        let applepay = this.paymenttypes.filter((paymenttype) => paymenttype.attributes.psp_method == 'applepay' && this.isAvailable(paymenttype));
        let googlepay = this.paymenttypes.filter((paymenttype) => paymenttype.attributes.psp_method == 'googlepay' && this.isAvailable(paymenttype));

        if (applepay.length > 0 || googlepay.length > 0) {
            if (applepay.length > 0) {
                this.paymenttypeApplePay = applepay[0];
                await _payment.init(this.amount, this.paymenttypeApplePay);
            }
            if (googlepay.length > 0) {
                this.paymenttypeGooglePay = googlepay[0];
                await _payment.init(this.amount, this.paymenttypeGooglePay);
            }
        } else {
            let paymenttypeIndex = -1;

            this.paymenttypes.some((paymenttype, index) => {
                if (
                    paymenttype.attributes.psp_method !== 'applepay' &&
                    paymenttype.attributes.psp_method !== 'googlepay' &&
                    this.isAvailable(paymenttype)
                ) {
                    paymenttypeIndex = index;
                    return true;
                }
            });

            if (paymenttypeIndex !== -1) {
                this.selectPaymenttype(this.paymenttypes[paymenttypeIndex]);
            }
        }
    },

    methods: {
        async getPaymenttypes() {
            this.paymenttypes = await _api.get('paymenttypes', this.transaction.account.id);
        },

        clearPaymenttype() {
            this.selectedPaymenttype = {};
            this.$emit('selected', {});
        },

        async selectPaymenttype(paymenttype) {
            if (this.disabled === true || this.loading === true) {
                return;
            }

            this.loading = true;

            this.$emit('selected', paymenttype);

            this.valid = true;
            this.selectedPaymenttype = paymenttype;

            await _transaction.setTransactionFee(paymenttype);
            await _payment.init(this.amount, paymenttype);

            this.loading = false;
        },

        isAvailable(paymenttype, amount) {
            let browserVersion = this.getBrowserVersion();

            if (paymenttype.attributes.psp_method == 'applepay' && browserVersion !== 'safari') {
                return false;
            }

            if (paymenttype.attributes.amount_minimum != null) {
                if (paymenttype.attributes.amount_minimum >= 0 && this.amount <= paymenttype.attributes.amount_minimum) {
                    return false;
                }
            }

            if (this.amount > 0 && paymenttype.attributes.psp_type === null && paymenttype.attributes.deferred_payment === false) {
                return false;
            }

            if (amount <= 0 && paymenttype.attributes.psp_type !== null) {
                return false;
            }

            // todo: rewrite
            if (this.transaction.vouchers.length > 0 && this.transaction.total_vouchers > 0 && this.transaction.total_payable == 0) {
                return paymenttype.attributes.voucher_payment;
            }
            return true;
        },

        // todo: cleanup code
        validateForm() {
            window.checkout.validateForm();

            this.scrollToTop();
        },

        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },

        // todo: cleanup codez
        getBrowserVersion() {
            let userAgent = navigator.userAgent;
            if (userAgent.match(/chrome|chromium|crios/i)) {
                return 'chrome';
            } else if (userAgent.match(/firefox|fxios/i)) {
                return 'firefox';
            } else if (userAgent.match(/safari/i)) {
                return 'safari';
            } else if (userAgent.match(/opr\//i)) {
                return 'opera';
            } else if (userAgent.match(/edg/i)) {
                return 'edge';
            }
            return false;
        },

        // todo: refactor
        validate() {
            if (!this.selectedPaymenttype.id) {
                this.valid = false;

                return this.valid;
            }
            if (this.isAvailable(this.selectedPaymenttype) === false) {
                this.valid = false;

                return this.valid;
            }

            if (_payment.isValid() === false) {
                this.valid = false;

                return this.valid;
            }

            if (this.selectedPaymenttype.attributes.required_account == true && !this.account_id) {
                this.saving = false;
                this.error_messages.push({
                    message: this.$t('order.account_required_paymenttype') + this.selectedPaymenttype.attributes.name,
                });
                this.scrollToTop();

                this.valid = false;

                return this.valid;
            }

            if (this.selectedPaymenttype.attributes.voucher_payment) {
                if (!(this.transaction.vouchers.length > 0 && this.transaction.total_vouchers > 0 && this.transaction.total_payable == 0)) {
                    this.saving = false;
                    // todo
                    // this.error_messages.push({
                    //     message: this.$t('order.account_required_paymenttype'),
                    // })
                    this.scrollToTop();

                    this.valid = false;

                    return this.valid;
                }
            }

            this.valid = true;

            return this.valid;
        },
    },
};
</script>

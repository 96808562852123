<template>
    <a @click="open()"
       class="h-10 btn btn-primary btn-qr"
    >
        {{ $t('wallettokenscanner.open') }}
    </a>

    <transition name="blur-background">
        <blur v-if="show">
        </blur>
    </transition>

    <transition name="modal-small">
        <div class="fixed w-full inset-0 overflow-y-auto ccss--modal-login fixed-modal-wrapper" v-show="show">
            <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">

                <div
                    class="modal inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full radius-anchor"
                    role="dialog" aria-modal="true" aria-labelledby="modal-headline"
                >
                    <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div class="sm:flex sm:items-start">
                            <div class="text-center sm:text-left px-4  sm:rounded-sm sm:px-10 pb-0 mx-auto">
                                <h3 class="mt-3 text-lg leading-6 font-bold text-2xl text-gray-900" id="modal-headline">
                                    {{ $t('wallettokenscanner.title') }}
                                </h3>
                            </div>
                        </div>
                        <div>
                            <div class="mt-5 camera-placeholder">
                                <div v-if="camerastatus == 1" class="flex justify-center">
                                    {{ $t('wallettokenscanner.camerabusy') }}...
                                </div>
                                <button v-if="camerastatus == 3" type="button" @click="openCamera()"
                                        class="mb-10 items-center cursor-pointer inline-flex justify-center w-full rounded-sm px-4 py-2 btn btn-primary text-lg leading-6 font-medium shadow-sm focus:outline-none sm:text-sm sm:leading-5"
                                >
                                    {{ $t('wallettokenscanner.cameraopenagain') }}
                                </button>

                                <barcode-reader
                                    ref="barcodereader"
                                    @decode="onDecode"
                                    @loaded="onLoaded"
                                ></barcode-reader>

                                <div class="p-4 rounded-sm mt-3 text-center" v-if="camerastatus == 3 && result">
                                    {{ $t('wallettokenscanner.scanned') }}: {{ result }}
                                    <div v-if="notification == 'success'">
                                        <p class="text-center font-sm text-green-500">
                                            {{ $t('wallettokenscanner.notification.success') }}
                                            {{ initialitem.attributes.description }}
                                        </p>
                                    </div>
                                    <div v-if="notification == 'failed'">
                                        <p class="text-center font-sm text-red-500">
                                            {{ $t('wallettokenscanner.notification.failed') }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-4 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button type="button" @click="close()" class="btn btn-close w-full">
                            {{ $t('button.close') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
// components
import BarcodeReader from './BarcodeReader.vue';
import Blur from "./Blur";

export default {
    components: {
        BarcodeReader,
        Blur,
    },
    data () {
        return {
            show: false,
            camerastatus: 1, // 1 = loading, 2 = stream, 3 = finished
            result: null,
        };
    },

    computed: {
        location_slug () {
            return this.$route.params.location_slug;
        },
    },

    methods: {
        open () {
            this.openCamera();
            this.show = true;
        },

        openCamera () {
            this.reset();
            this.camerastatus = 1;

            this.$refs.barcodereader.openCamera();
        },

        close () {
            this.show = false;
            this.$refs.barcodereader.closeCamera();
        },

        reset () {
            this.notification = null;
        },

        onLoaded () {
            this.camerastatus = 2;
        },

        async onDecode (content) {

            this.$emit('scanned', content);
            this.close();
        },
    },
};
</script>

<style scoped>
.camera-placeholder {
    height: 230px;
}
</style>

import * as _payment from '../lib/payment';

export default {

    methods: {
        paymenttypeValidate() {
            if (this.paymenttypesActive === false) {
                return true;
            }

            if (this.paymenttypeAvailable(this.paymenttype) === false) {
                return false;
            }

            if (_payment.isValid() === false) {
                return false;
            }


            if (this.paymenttype.attributes.required_account == true && !this.form.account_id) {
                this.saving = false
                this.error_messages.push({
                    message: this.$t('order.account_required_paymenttype') + this.paymenttype.attributes.name,
                })
                this.scrollToTop()
                return false
            }

            if (this.paymenttype.attributes.voucher_payment) {
                if (!(this.transaction.vouchers.length > 0 && this.transaction.total_vouchers > 0 && this.transaction.total_payable == 0)) {
                    this.saving = false
                    // todo
                    // this.error_messages.push({
                    //     message: this.$t('order.account_required_paymenttype'),
                    // })
                    this.scrollToTop()
                    return false
                }
            }
            return true
        },

        paymenttypeAvailable(paymenttype, amount) {

            // if (paymenttype.attributes.psp_method == 'applepay') {
            //     return  /^(iPhone|iPad|iPod)/.test(navigator.platform);
            // }

            if (amount > 0 && paymenttype.attributes.psp_type === null && paymenttype.attributes.deferred_payment === false) {
                return false;
            }

            if (amount <= 0 && paymenttype.attributes.psp_type !== null) {
                return false;
            }

            // todo: rewrite
            if (this.transaction.vouchers.length > 0 && this.transaction.total_vouchers > 0 && this.transaction.total_payable == 0) {
                return paymenttype.attributes.voucher_payment
            } else {
                return !paymenttype.attributes.voucher_payment
            }
        },

        async paymenttypeSelect(paymenttype) {
            this.paymenttype = paymenttype;
            this.form.paymenttype_id = paymenttype.id;

            // todo: set transactioncosts
            // this.$store.dispatch('transaction/setTransactioncosts', paymenttype.attributes.fee)

            await _payment.init(paymenttype);

            if (paymenttype.attributes.psp_type === 'adyen_ecommerce' && paymenttype.attributes.psp_method === 'applepay') {
                if (await this.$refs.form.validate() == false) {
                    return;
                }
                await this.onSubmit(this.form);
            }
        },
    }
}
